import React from 'react'
import { Heading } from 'theme-ui'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Heading>Not found</Heading>
    <p>Oops. This page couldn't be found.</p>
  </Layout>
)

export default NotFoundPage
